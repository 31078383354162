<template>
    <section class="op-productos-config">
        <navbar-admin>
            <el-tooltip content="Eliminar" placement="bottom-end" effect="light" visible-arrow>
                <button
                        class="btn btn-square32-5d ml-2 cr-pointer"
                        :class="permitAction('obras.equipo.eliminar.configuracion') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('obras.equipo.eliminar.configuracion', deleteConfiguration)"
                    >
                    <i class="icon-trash-can-outline text-white" />
                </button>
            </el-tooltip>
        </navbar-admin>
        <!-- nombre -->
        <div class="row">
            <div class="col-12 col-lg-9 mb-3 mb-lg-0">
                <div class="bg-f9 border-dd br-4 px-3 h-52px d-middle">
                    <div class="row mx-0 w-100">
                        <div class="col my-auto">
                            <p class="f-600">{{ configuracion.configuracion }}</p>
                        </div>
                        <div class="col-auto my-auto ml-auto pr-0">
                            <div class="d-flex">
                                <el-tooltip content="Cantidad y valor de los materiales" placement="bottom" effect="light" visible-arrow>
                                    <i class="icon-format-list-numbered f-18 mr-1" />
                                </el-tooltip>
                                <p class="my-auto f-14">{{ configuracion.materiales }} - $ {{formatoMoneda(configuracion.valor_total_materiales)}}</p>
                            </div>
                        </div>
                        <div class="col-auto my-auto ml-auto pr-0">
                            <div class="d-middle">
                                <i class="icon-account-clock f-18 mr-1" />
                                <div class="my-auto f-14 d-middle">
                                    {{ configuracion.horas_hombre }} Hrs. - $ <p>{{formatoMoneda(configuracion.horas_hombre * configuracion.valor_hora_hombre )}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col col-lg-3">
                <div class="bg-f9 border br-4 px-3 d-middle-center h-52px">
                    <p class="f-600">$ {{ formatoMoneda(configuracion.total_final) }}</p>
                </div>
            </div>
        </div>
        <!-- add material -->
        <div class="row mb-3 mt-4">
            <div class="col-12 col-lg-auto my-auto">
                <p class="f-14 f-600">Añadir material</p>
            </div>
            <div class="col-12 col-lg-4 position-relative my-auto">
                <p class="input-label-top">Nombre del material</p>
                <el-select v-model="mat" filterable reserve-keyword placeholder="Nombre del material" size="small" class="w-100" @change="getAmount" >
                    <el-option v-for="item in selectMateriales" :key="item.id" :label="item.material" :value="item.id"/>
                </el-select>
            </div>
            <div class="col-12 col-lg-2 position-relative my-auto">
                <p class="input-label-top">Unidades</p>
                <el-input v-model="inputCantUnidades" placeholder="Cant unidades" size="small" class="w-100" />
            </div>
            <!-- agregar material -->
            <div class="col-auto icon-option my-auto px-1">
                <el-tooltip slot="reference" content="Añadir material" placement="bottom" effect="light" visible-arrow>
                    <i
                        class="icon-plus-circle f-26 cr-pointer"
                        :class="permitAction('obras.configuraciones.agregar.material') ? 'cr-pointer' : 'cr-not-allowed'"
                        @click="functionPermitAction('obras.configuraciones.agregar.material', agregarMaterial)"
                    />
                </el-tooltip>
            </div>
            <!-- buscar en tabla -->
            <div class="col-auto icon-option ml-auto my-auto px-1">
                <el-input v-model="filter.buscar" placeholder="Buscar material en la lista" size="small" class="w-100" @keypress.native.enter="(event) => {listarMaterialesConfiguracion()}" />
            </div>
            <!-- abrir filtros -->
            <div class="col-auto icon-option my-auto pl-0">
                <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                    <button class="btn btn-square32-5d ml-2 cr-pointer" @click="abrirModalFiltros">
                        <i class="icon-filter text-white" />
                    </button>
                </el-tooltip>
            </div>
        </div>
        <!-- <tabla-general :mostrarBuscador="false" alturaTabla="500px" :data="listadoConfiguracion"> -->
        <tabla-general :mostrarBuscador="false" alturaTabla="500px" :usarServidor="true" :usarPaginacion="true" :servidorData="materialesConfiguracion" @paginar="listarMaterialesConfiguracion">
            <el-table-column label="Ref" prop="ref" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos"> {{ scope.row.referencia }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Nombre" prop="nombre">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ scope.row.nombre }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Tipo" prop="tipo" width="100">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ scope.row.tipo_material }}</p>
                </template>
            </el-table-column>
            <el-table-column label="U.M." prop="um" width="80">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ scope.row.sigla }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Valor cotizado" prop="valorCotizado">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 tres-puntos">{{ formatoMoneda(scope.row.valor_cotizado) }}</p>
                </template>
            </el-table-column>
            <el-table-column label="Unidades" prop="unidades" width="90">
                <template slot-scope="scope">
                    <div
                        v-if="!permitAction('obras.configuracion.materiales.cambiar.unidades')"
                        class="d-middle justify-content-between border br-4 px-2"
                    >
                        <p
                            :class="permitAction('obras.configuracion.materiales.cambiar.unidades') ? 'cr-pointer' : 'cr-not-allowed'"
                            @click="functionPermitAction('obras.configuracion.materiales.cambiar.unidades', () => {})"
                        >
                            {{ scope.row.cantidad }}
                        </p>
                    </div>
                    <el-input v-else v-model="scope.row.cantidad" size="small" maxlength="7" @change="actualizarMaterial(scope.row)" @keypress.native.enter="actualizarMaterial(scope.row)"/>
                </template>
            </el-table-column>
            <el-table-column label="Plan de mantenimiento" prop="planMantenimiento">
                <template slot-scope="scope">
                    <div
                        v-if="!permitAction('obras.configuracion.materiales.agregar.plan.mantenimiento')"
                        :class="permitAction('obras.configuracion.materiales.agregar.plan.mantenimiento') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('obras.configuracion.materiales.agregar.plan.mantenimiento', () => {})"
                        class="d-middle justify-content-between border br-4 px-2"
                        style="height: 32px; background: #f5f7fa;"
                    >
                        <p>{{ nombrePlanMtto(scope.row.planes_mantenimiento, scope.row.id_plan_mantenimiento)}}</p>
                        <i class="icon-down-open"></i>
                    </div>
                    <el-select v-else v-model="scope.row.id_plan_mantenimiento" placeholder="Seleccionar plan" size="small" @change="actualizarMaterial(scope.row)">
                        <el-option v-for="item in scope.row.planes_mantenimiento" :key="item.id" :label="item.nombre" :value="item.id"/>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column width="50">
                <template slot-scope="scope">
                    <i v-if="editMode" class="icon-trash-can-outline f-20 text-general "
                        :class="permitAction('obras.configuraciones.eliminar.material') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('obras.configuraciones.eliminar.material', eliminarMaterial, [scope.row])"
                    />
                    <i v-else class="icon-information-outline f-20 text-general" />
                </template>
            </el-table-column>
        </tabla-general>
        <!-- modales -->
        <modal-eliminar ref="deleteConfiguration" title="Eliminar configuración" mensaje="¿Está seguro que quiere eliminar esta configuración?" @delete="deleteConfiguracion"/>
        <modal-eliminar ref="abrirModalEliminarConfiguracion" title="Eliminar material" mensaje="¿Está seguro que quiere eliminar este material?" @delete="deleteMaterial"/>
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-4">
                    <p class="input-label-top">Tipo de material</p>
                    <el-select v-model="filter.id_tipo_material" placeholder="Seleccionar material" size="small" class="w-100">
                        <el-option v-for="item in dataFiltro.tipos_materiales" :key="item.id" :label="item.tipo_material" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-4">
                    <p class="input-label-top">Unidad de medida</p>
                    <el-select v-model="filter.id_unidad_medida" placeholder="Seleccionar U.M" size="small" class="w-100">
                        <el-option v-for="item in dataFiltro.unidades_medida" :key="item.id" :label="item.unidad_medida" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 my-4">
                    <p class="input-label-top">Rango de valor</p>
                    <!-- <el-slider v-model="valueRangeValores" range :min="filters.rango_valor[0]" :max="filters.rango_valor[1]" :format-tooltip="formatTooltipRange" /> -->
                    <el-slider v-model="range_value" :disabled="get_range_value[0] === get_range_value[1]" range :min="get_range_value[0]" :max="get_range_value[1]" :format-tooltip="formatTooltipRange" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="(event) => {listarMaterialesConfiguracion()}"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { Notification } from 'element-ui'
export default {
    data(){
        return{
            visible: false,
            inputCantUnidades: '',
            editMode: true,
            valueRangeValores: [2000, 6500],
            infoConfiguracion: {
                idOportunidad:this.$route.params.idOportunidad,
                idProducto:this.$route.params.idProducto,
                idConfiguracion:this.$route.params.idConfiguracion,
                tabla:this.$route.params.tabla
            },
            dataMaterial:{},
            mat:'',
            payload: {
                id:this.$route.params.id,
                id_configuracion:this.$route.params.id_configuracion,
                tabla:this.$route.params.tabla
            }
        }
    },
    computed: {
        ...mapGetters({
            configuracion: 'obras/configuracion/configuracion',
            selectMateriales: 'obras/configuracion/selectMateriales',
            materialesConfiguracion: 'obras/configuracion/materialesConfiguracion',
            dataFiltro: 'obras/configuracion/dataFiltro',
            filters: 'obras/configuracion/filters',
            // oportunidadesInformacion: 'oportunidades/oportunidades/oportunidadesInformacion',
        }),
        iconMode(){
            return this.editMode ? 'icon-trash-can-outline' : 'icon-information-outline'
        },
        get_range_value(){
            return [
                this.dataFiltro?.rango_valor?.[0] ?? 0,
                this.dataFiltro?.rango_valor?.[1] ?? 0
            ]
        },
        range_value:{
            get(){
                return [this.filter.valor_minimo, this.filter.valor_maximo]
            },
            set(val){
                this.filter.valor_minimo = val[0]
                this.filter.valor_maximo = val[1]
            },
        },
        filter:{
            get(){ return this.filters },
            set(val){ this.set_filters(val)}
        },



    },

    async created () {
        await this.getDataFiltroMateriales()
        this.Action_limpiar_filtros()
        await this.listarConfiguracion()
        await this.selectMats()
        await this.listarMaterialesConfiguracion()
        this.migasPan()
    },
    methods:{
        ...mapActions({
            Action_get_configuracion: 'obras/configuracion/Action_get_configuracion',
            Action_get_select_materiales: 'obras/configuracion/Action_get_select_materiales',
            Action_get_materiales_configuracion: 'obras/configuracion/Action_get_materiales_configuracion',
            Action_post_materiales_configuracion: 'obras/configuracion/Action_post_materiales_configuracion',
            Action_put_actualizar_material: 'obras/configuracion/Action_put_actualizar_material',
            Action_limpiar_filtros: 'obras/configuracion/Action_limpiar_filtros',
            Action_delete_material: 'obras/configuracion/Action_delete_material',
            Action_delete_configuracion: 'obras/configuracion/Action_delete_configuracion',
            Action_get_data_filtro: 'obras/configuracion/Action_get_data_filtro',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb',
        }),
        ...mapMutations({
            set_filters: 'obras/configuracion/set_filters',
        }),
        migasPan() {
            this.spliceBreadcumbs([
                {
                    position: 3,
                    breadcumb: {
                        name: this.configuracion.configuracion,
                        route: 'obras.vigentes.ver.equipos',
                        params: { id: this.$route.params.id },
                    }
                },
            ])
        },
        async getDataFiltroMateriales(){
            await this.Action_get_data_filtro(this.payload)
        },
        nombrePlanMtto(planes_mantenimiento, id_plan_mantenimiento){
            return planes_mantenimiento.find(e => e.id == id_plan_mantenimiento)?.nombre ?? 'Seleccionar plan'
        },
        async agregarMaterial(){
            if (!this.mat || !this.inputCantUnidades) {
                new Notification({
                    message: 'El campo nombre y unidades son requeridos',
                    type: 'error',
                    dangerouslyUseHTMLString: false
                })
                return
            }
            const payload = {
                id_material:this.mat,
                cantidad:this.inputCantUnidades,
                tabla:this.infoConfiguracion.tabla
            }
            await this.Action_post_materiales_configuracion({params:this.payload, payload})
            await this.listarConfiguracion()
            await this.Action_get_materiales_configuracion({...this.payload, tipo:1})
            await this.selectMats()
            this.mat = ''
            this.inputCantUnidades = ''
        },
        getAmount(id){
            this.inputCantUnidades = this.selectMateriales?.find(e => e.id === id)?.cantidad ?? 0
        },
        async listarConfiguracion(){
            await this.Action_get_configuracion(this.payload)
        },
        async listarMaterialesConfiguracion(page=1){
            await this.Action_get_materiales_configuracion({...this.payload, page})
        },

        deleteConfiguration(){
            this.$refs.deleteConfiguration.toggle()
        },
        async deleteConfiguracion(){
            await this.Action_delete_configuracion(this.payload)
            this.$router.push({ name: 'obras.vigentes.ver.equipos',
                params: {
                    id: this.$route.params.id
                }
            })
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        eliminarMaterial(value){
            this.dataMaterial = {
                id:value.id_oportunidades_equipos_modelos_etapas_materiales,
                tabla:this.payload.tabla
            }
            this.$refs.abrirModalEliminarConfiguracion.toggle()
        },
        async deleteMaterial(){
            await this.Action_delete_material({params:this.payload, payload:this.dataMaterial})
            await this.listarMaterialesConfiguracion()
            await this.listarConfiguracion()
            await this.selectMats()
            this.dataMaterial = {}
        },
        async setDefaultPrice(){
            const payload = {
                id: this.configuracion.id,
                tabla: this.configuracion.tabla,
            }
            await this.Action_put_restablecer_valor_horas_hombre({params:this.infoConfiguracion, payload})
            await this.listarConfiguracion()
        },
        async actualizarMaterial(value){
            const payload = {
                id:value.id_oportunidades_equipos_modelos_etapas_materiales,
                cantidad:value.cantidad,
                id_mantenimiento:value.id_plan_mantenimiento,
                tabla:this.payload.tabla
            }
            await this.Action_put_actualizar_material({params:this.payload, payload})
            await this.listarConfiguracion()
        },
        async selectMats(){
            await this.Action_get_select_materiales(this.payload)
        },
        async limpiarFiltro(){
            await this.Action_limpiar_filtros()
            await this.listarMaterialesConfiguracion()
            this.$refs.abrirModalFiltros.toggle()
            //await this.listar()
        },
    }

}
</script>

<style lang="scss" scoped>
.op-productos-config{
    .h-52px{
        height: 53px;
    }
    .icon-option{
        .icon-plus-circle, .icon-text-box-plus-outline, .icon-upload-outline, .icon-trash-can-outline{
            &:hover{
                color: var(--color-general);
            }
        }
    }
    .icon-trash-can-outline{
        &:hover{
            color: var(--color-general);
        }
    }
    .input-label-top{
        position: absolute;
        top: -15px;
    }
    .text-confi-per{
        color: #E1C71B;
    }
}
</style>